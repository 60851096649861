// Container
header {

  .mobile {
    right: 0;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
  }


  @include mobile-up {
    .mobile-dropdown {
      display: none
    }
  }

  nav {

    @media only screen and (max-width: 576px) {
      flex-direction: row !important;
      flex-wrap: wrap;
      padding: 0 !important;

      .mobile-header {
        height: 80px;
        justify-content: center;
        align-items: center;

        .right {
          display: flex;
          height: 100%;
          align-items: center;
        }

        .logout {
          text-decoration: none;
          justify-self: end;
          max-width: 100px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: rgba(0, 0, 0, 0.06);
          padding: 0 1rem;
          font-weight: 600;
        }
      }
    }

    h2 {
      margin: 1rem 0 0;
      font-size: 1.5rem;
      text-align: center;
    }

    .followers {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;

      @media only screen and (max-width: 576px) {
        margin-top: 2rem;
      }

      p {
        max-width: 65%;
        line-height: 1;
        margin: 0;
        position: relative;
        font-size: 1.1rem;
      }

      h3 {
        font-size: 2.5rem;
        text-align: center;
        font-weight: 600;
        position: relative;
        text-transform: uppercase;

        span {
          display: block;
          font-size: 1.05rem;
          text-transform: uppercase;
          letter-spacing: 0;
          line-height: 1;
        }

        &::after {
          content: '';
          position: absolute;
          top: -1rem;
          height: 2px;
          right: 0;
          width: 35%;
          background: $dtmYellow;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: -1.5rem;
          height: 2px;
          left: 0;
          width: 50%;
          background: $dtmYellow;
        }

      }

      .footer__socials {
        justify-content: flex-end;
        position: relative;
        gap: .5rem;
      }
    }

    .bg-img {
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 100%;
      overflow: hidden;
      left: 0;
      z-index: -1;

      @media only screen and (max-width: 576px) {
        display: none;
      }

      img {
        width: 200%;
        position: absolute;
        bottom: -20%;
        left: -10%;
        opacity: .05;
        pointer-events: none !important;
      }
    }

    .nav-btn-group {
      width: 100%;

      &>*+* {
        margin-top: 1rem;
      }
    }

    .btn {
      width: 100%;
      text-align: center;
      line-height: 1;
      border-radius: 10px;
      text-decoration: none;
      font-weight: 600;
      font-size: 1rem;

      @media only screen and (max-width: 576px) {
        position: relative;
        width: 100%;
        left: 0;
        bottom: 0;
      }
    }

    @media only screen and (max-width: 576px) {
      &>*:not(.mobile-menu, .mobile-dropdown) {
        display: none;
      }
    }
  }
}

// Top Menu
.top-menu {
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  .menu-btn {
    background-color: #e1fa00;
    border-radius: 15px;
    color: #000;
    display: block;
    font-family: "roc-grotesk-extrawide", sans-serif;
    font-size: 12px;
    line-height: 1;
    margin: 0 0 1rem;
    padding: 1.1rem 0 .85rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 250ms all linear;
    width: 100%;

    &:last-child {
      margin: 0;
    }

    &:hover {
      -webkit-transform: rotate(2.9deg);
      transform: rotate(2.9deg);
    }

  }

}



#menuToggle {
  display: block;
  position: relative;
  top: 50px;
  left: 50px;

  z-index: 1;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: color 0.3s ease;
}

#menuToggle a:hover {
  color: tomato;
}


#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0;
  /* hide this */
  z-index: 2;
  /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  z-index: 1;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/*
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;

  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(-100%, 0);

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked~ul {
  transform: none;
}



/********************************************
Navigation
********************************************/

nav {
  padding: 0 !important;
}


/********************************************
Menu
********************************************/

ul.menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.25rem;
  margin: 0;
  padding: 0 1rem 0 0;
  font-size: 14px;
  font-family: 'roc-grotesk-wide', sans-serif;
  text-transform: uppercase;

  li {
    height: 100%;
    display: flex;
    align-items: stretch;
    position: relative;
    cursor: pointer;
    color: #fff;

    >span {
      padding: 0 0.5rem;
    }

    a {
      margin: 0;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      transition: all .3s ease;
      text-decoration: none;
      color: #fff !important;

      &::after {
        content: '';
        position: absolute;
        width: calc(100% + 20px);
        height: 0;
        background: $dtmYellow;
        bottom: 0;
        left: -10px;
        transition: height .3s ease, opacity .2s ease;
        opacity: 0;
      }

      &.active::after,
      &:hover::after {
        height: 3px;
        opacity: 1;
      }

    }

    &.hasSubmenu {
      span {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 18px;
          margin: 0 0 0 3px;

          path {
            fill: $dtmYellow
          }
        }
      }

      &:hover .submenu {
        display: block;
      }

      .submenu {
        display: none;
        bottom: 0;
        position: absolute;
        left: -0.5rem;
        text-align: left;
        -webkit-transform: translate(50%, 100%);
        transform: translate(0, 100%);
        background-color: #090909;
        padding: 0;
        width: 200px;

        li {
          overflow: hidden;

          a {
            display: block;
            width: 100%;
            padding: 1rem;
            text-align: left;
            white-space: nowrap;
            font-size: 12px;

            &:hover::after,
            &.active::after {
              height: 100%;
              opacity: 1;
              width: 3px;
              left: 0;
            }

            span {
              justify-content: flex-start;
            }
          }
        }
      }

    }
  }
}


/********************************************
Top Navigation
********************************************/

#top {
  display: flex;
  align-items: stretch;
  padding: 0;
  height: 75px;

  @include tablet-down {
    display: none !important
  }

  /* Branding */
  a.branding {
    margin: 0;

    img {
      width: 75px;
    }
  }
}

/********************************************
Sidebar Navigation
********************************************/

main.nav-default,
main.nav-sidebar {
  flex-direction: row;

  header {
    width: 25vw;
  }
}

#sidebar {
  display: flex;
  justify-content: center;
  height: 100vh;
  position: sticky;
  top: 0;
  padding: 2rem !important;

  @include tablet-down {
    display: none !important
  }

  /* Menu */

  ul.menu {
    flex-direction: column;
    gap: 0;
    padding: 0;
    width: 100%;
    align-items: flex-start;
    margin: auto 0 0;
  }

  /* Branding */

  .branding-container {
    margin: 0 auto auto;

    a {
      display: block;
    }

    img {
      width: 200px;
      margin: 0 auto;
      display: block;
    }
  }

  /* Socials */

  .footer__socials {
    display: flex;
    gap: 1em;
    margin: 0 0 1.5rem;
    position: relative;
  }

  .footer__social {
    align-items: center;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    transition: all 0.3s ease;
    -webkit-transform: scale(0.85);
    transform: scale(0.85);

    &:hover {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    svg path {
      fill: $dtmYellow;
      transition: fill 0.5s ease;
    }

  }

  /* Buttons */
  .btns {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: auto 0 0;
  }

}

/********************************************
Mobile Navigation
********************************************/


#mobile {
  width: 100%;

  @include tablet-up {
    display: none !important;
  }

  .branding {
    img {
      width: 100px;
      margin: 0 auto;
      display: block;
    }
  }

  @include mobile-down {

    button[alt="close"] {
      display: none;
    }

    &.menuExpanded {
      button[alt="home"] {
        display: none
      }

      button[alt="close"] {
        display: block;
      }

      .mobile-dropdown {
        height: unset;
      }
    }

    .mobile-header {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 1.25rem 0 0.5rem;

      .menu-button {
        button {
          width: 45px;
          height: 45px;
          background-color: $dtmYellow;
          border-radius: 50%;
          padding: 5px;
          box-sizing: content-box;
        }
      }
    }
  }
}