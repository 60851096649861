.single-video {
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.5rem 0;
  color: $dtmBlack;

  h1 {
    font-family: $dtmFont;
    color: $dtmBlack;
  }

  h2 {
    color: $dtmBlack;
    font-weight: 700;
    text-align: left;
    line-height: 1;
    margin-bottom: .25rem;
    font-size: 1.75rem;
  }

  h3 {
    color: $dtmBlack;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  h4 {
    text-align: left;
    font-size: 1rem;
    margin-bottom: 0;
    font-weight: 400;
  }

  .brand-logo {
    width: 60px;
    height: 60px;
  }

  .content-section {
    position: relative;
    width: 100%;
    padding: 0 4rem;

    @include desktop-down {
      padding: 0;
    }

    &>div:first-of-type {
      margin: 0 auto;
      max-height: unset !important;
      width: 100% !important;
      height: unset !important;
      aspect-ratio: 64/36 !important;
      border: 2px solid;
      border-radius: 30px;
      overflow: hidden;

      @include desktop-down {
        border-radius: 0;
        border: 0;
      }
    }

    table {
      color: $dtmBlack;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 1rem;

      tr {
        border: 1px solid rgba(0, 0, 0, 0.1);

        &:nth-child(odd) {
          background: rgba(0, 0, 0, 0.03);
        }

        &:hover {
          background: rgba(0, 0, 0, 0.03);
        }

        td {
          border-right: 1px solid rgba(0, 0, 0, 0.1);
        }

        td:first-child {
          font-weight: 600;
        }

        td:last-child {
          border-right: 0;
        }
      }
    }

    .top-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 1rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid #eee;

      @include desktop-down {
        padding-left: 2rem;
        padding-right: 2rem;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 2rem;

        .brand {
          margin-bottom: 1rem;

          h2 {
            font-size: 1.25rem;
          }
        }
      }

      .buttons {
        flex: 0 0 30%;
        min-width: 300px;

        @include desktop-down {
          flex: 0 0 auto;
          width: 100%;
        }

        .btn {
          flex-grow: 1;

          @include desktop-down {
            width: 100%;
          }
        }
      }
    }

    .bottom-section {
      display: flex;
      gap: 2rem;
      padding-top: 2rem;
      justify-content: center;
      align-items: flex-start;

      @include desktop-down {
        padding-right: 2rem;
        padding-left: 2rem;
        flex-direction: column;
      }
    }

    .tag-grid {
      display: flex;
      column-gap: 10px;
      flex-wrap: wrap;
      row-gap: 10px;
      justify-content: flex-start;
    }
  }

  .video-details {
    flex: 1 1 auto;

    h3 {
      text-align: left;
    }

    .details-row {
      color: $dtmBlack !important;
      display: flex;
      flex-direction: column;
      gap: .5rem;

      span {
        font-weight: 600;
      }
    }
  }

  .tags {
    flex: 0 0 30%;
    min-width: 300px;

    h3 {
      text-align: left;
    }
  }

  .tag {
    border: 2px solid #ddd;
    display: inline;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.03);
  }

  .brand {
    display: flex;
    align-items: center;
    gap: 20px;

    h3 {
      margin-bottom: 0;
    }
  }

  .buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .add-response {
    text-align: center;
    margin-top: 1rem;
  }
}