// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

// Variables
$fnnPrimary: #333;
$fnnSecondary: #ce0000;
$fnnTextLight: #ffffff;
$fnnTextDark: #333333;
$fnnFont: 'Roboto Condensed', sans-serif;

// Styles
.fakenewsnetwork {

    // Base
    @include brand (
        $fnnPrimary,
        $fnnSecondary,
        $fnnFont,
        $fnnTextLight,
        $fnnTextDark
    );

    // Overrides

    .btn.primary,
    .logout,
    nav,
    .mobile-header,
    .mobile-dropdown,
    .checkbox.checked::after,
    .mobile-dropdown .admin-navigation a,
    .file-upload::file-selector-button,
    .branding-container h2,
    .followers :is(h3, p),
    .admin-navigation a {
        color: $fnnTextLight !important;
    }

}
