// Fonts
@font-face {
    font-family: 'Odibee Sans';
    src: url(../../../src/assets/fonts/OdibeeSans/OdibeeSans-Regular.ttf) format('truetype');
}

// Variables
$drunkPrimary: #2ea88c;
$drunkSecondary: #f7ba02;
$drunkTextLight: #ffffff;
$drunkTextDark: #080708;
$drunkFont: 'Odibee Sans', sans-serif;

// Styles
.drunkpeopledoingthings {

    // Base
    @include brand (
        $drunkPrimary,
        $drunkSecondary,
        $drunkFont,
        $drunkTextDark,
        $drunkTextDark
    );

    // Overrides
    button.btn.primary,
    button.primary.submit-btn {
        background-color: $drunkSecondary !important;
        color: #000000 !important;
    }

}