// Fonts
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap');

// Variables
$liquidPrimary: #dbdbdb;
$liquidSecondary: #ffb132;
$liquidTextLight: #ffffff;
$liquidTextDark: #333333;
$liquidFont: 'Comic Neue', sans-serif;

// Styles
.liquidnostalgia {

    // Base
    @include brand (
        $liquidPrimary,
        $liquidSecondary,
        $liquidFont,
        $liquidTextLight,
        $liquidTextDark
    );

    // Overrides
    .btn.alt,
    .nav-link.active,
    .form-head h4 a,
    .btn.primary:hover {
        color: $liquidTextDark !important;
    }

}
