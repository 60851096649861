.sign-in-screen {
  width: 100%;
  background: #fff !important;
  margin: 0;
  min-height: 100vh;
  padding: 2rem;
  display: block;

  h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
    background: transparent !important;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 0;
    text-align: center;

    @include mobile-down {
      font-size: 2.5rem;
      padding: 0;
    }
  }

  // Form containers

  .login-form {
    width: 100%;
    max-width: 800px;
    margin: 0 auto !important;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }


  // Help text

  .forgot-container {
    display: flex;
    justify-content: flex-end;
  }

  .pw-disclaimer {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    width: 75%;
    margin: 0 auto;
    color: #777;

    @include mobile-down {
      width: 100%
    }
  }

  .forgot {
    width: auto;
    text-decoration: none;
    font-size: 1rem;
    color: $dtmBlack;

    @include mobile-down {
      margin: 0 auto 1rem;
      text-align: center;
      float: unset;
      display: inline-block;
    }
  }

  // Navigation

  .sign-in-nav {
    justify-content: space-between;

    @include desktop-down {
      // flex-direction: column;
      flex-wrap: nowrap;
      //border: 3px solid #ddd;
      border-radius: 12px;
      margin: 0 auto;
    }

    .nav-item {
      flex: 0 0 calc(100% / 2);
    }
  }

  .nav-item {
    width: 100% !important;
    text-decoration: none !important;
  }

  .nav-link {
    font-size: 1.5rem;
    background: transparent;
    padding: 1rem 1.5rem;
    text-align: center;
    font-weight: 300;
    transition: all .2s ease;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 4px solid $dtmBlack;
    font-family: $dtmFont;
    color: $dtmBlack;

    &.active {
      background-color: $dtmBlack;
      color: $dtmYellow;
    }

    @include desktop-down {
      // border-radius: 10px;
      // border-bottom: none;
      font-size: 1.15rem;
      padding: 10px;
      border-bottom: 2px solid #888;
    }
  }

  .inputsection {
    max-width: unset;
    padding: 0;
  }
}
