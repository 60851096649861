.forgot-screen {
    background: #fff;
    min-height: 100vh;
    padding: 2rem;
    margin: 0;
    display: flex;
    align-items: center;

    @include mobile-down {
        min-height: unset;
        padding: 3rem 2rem 0;
    }
}

.forgot-password {
    width: 100%;
    max-width: 800px;
    margin-inline: auto;
    min-height: unset;

    .button-row {
        margin-top: 1.5rem !important;
    }
}
