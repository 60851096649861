.license-screen {
  padding: 2.5rem;

  h1 {
    margin: 1.5rem 0 0 0
  }

  .options {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    grid-gap: 2rem;
    gap: 2rem;
    margin: 1.5rem 4rem calc(1rem + 15px);
  }

  #filter {
    justify-content: flex-end;
    gap: 10px;
    width: auto !important;
    flex-wrap: nowrap;

    .dropdown-menu {
      max-height: 50vh;
      overflow: scroll;
    }

    .dropdown-item {
      .checkbox {
        margin-top: 0;
        margin-right: 10px;
      }

      .form-check-label {
        display: flex;
        align-items: center;
      }
    }
  }

  .video-search-container {
    padding: 0;
  }

  .search-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    max-width: 50%;
    position: relative;
  }

  .search-terms {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    background-color: #fcfcfc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-top: 10px;

    &__label {
      color: #555;
      flex-shrink: 0;
      flex-grow: 1;
      width: 100%;
      font-size: .8rem;
    }

    &__container {
      display: flex;
      gap: 7px;
      flex-wrap: wrap;
    }

    &__term {
      border-radius: 4px;
      background-color: #eee;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      padding: 0 3px 0 12px;
      border: 2px solid #ddd;
    }

    &__text {
      color: $dtmBlack;
      font-weight: 400;
    }

    &__clear {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .featured-grid {
    display: grid;
    grid: auto / repeat(4, 1fr);
    gap: 2rem;

    .grid-item {
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 15px;
      position: relative;

      img.brand {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-15px, -15px);
        z-index: 100;
      }

      img.media-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
      }

      .react-player__preview {
        border-radius: 15px;
      }

      video {
        height: 400px !important;
        border-radius: 15px;
      }

      .link {
        font-size: 1.15rem;
        color: #000000;
        background: #e1fa00;
        text-decoration: none;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        padding: 1rem 0;
        font-weight: 700;
        text-transform: uppercase;
        transition: 250ms all ease;
        opacity: 0;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
      &:hover {
        .link {
          opacity: 1
        }
      }

    }
  }

  .row {
    padding: 0;
    margin: 0;
  }

  h2 {
    color: #000;
    font-family: 'roc-grotesk', sans-serif;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .dropdown-toggle {
    position: relative;
    padding: 12px 30px 10px 12px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 1;
    background-color: #000000;
    color: #ffffff;

    &::after {
      color: $dtmYellow;
      vertical-align: unset;
      position: absolute;
      top: 50%;
      right: 12px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .dropdown-row {
    display: flex;
    gap: 1.25rem;
  }

  .center-items {
    margin-bottom: 1.25rem;
  }

  .buttons {
    display: flex;

    .btn {
      width: 50%;
    }
  }

  .video-card-grid {
    display: grid;
    grid: auto / repeat(4, 1fr);
    gap: 1.5rem;

    .video-card-item {
      display: flex;
      flex-direction: column;

      .video-card {
        height: 100%;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border: 0;
        img {
          height: 40vh;
          object-fit: cover;
          border-radius: 0;
        }
      }


      .btn {
        border-radius: 20px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        text-transform: none;
        font-weight: 400;
        font-size: 1.2rem;
      }
    }
  }

  .card-footer {
    text-decoration: none;
    color: $dtmYellow;
    padding: 1rem;
  }
}

.card-body {
  padding: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
}

.pagination-bar {
  margin: 2rem 0;
}

.invalid {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
