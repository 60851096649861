// Fonts
@import url('https://fonts.googleapis.com/css2?family=Solway:wght@800&display=swap');

// Variables
$bugsPrimary: #0d421e;
$bugsSecondary: #fbbd18;
$bugsTextLight: #ffffff;
$bugsTextDark: #0d421e;
$bugsFont: 'Solway', serif;

// Styles
.bugsdoingthings {

    // Base
    @include brand (
        $bugsPrimary,
        $bugsSecondary,
        $bugsFont,
        $bugsTextLight,
        $bugsTextDark
    );

    // Overrides
    .btn.alt,
    .nav-link.active,
    .form-head h4 a,
    .btn.primary:hover {
        color: $bugsTextDark !important;
    }

    .btn.primary,
    .logout,
    nav,
    .mobile-header,
    .mobile-dropdown,
    .checkbox.checked::after,
    .mobile-dropdown .admin-navigation a,
    .file-upload::file-selector-button,
    .branding-container h2,
    .followers :is(h3, p),
    .admin-navigation a {
        color: $bugsTextLight !important;
    }

    .followers svg path {
        fill: $bugsTextLight !important;
    }

    .followers h3::before,
    .followers h3::after {
        background: $bugsTextLight !important;
    }
}
