/********** Container Styles **********/

.video-banner{
  overflow: auto;
}

.black-container-with-top-border {
  width: 100%;
  background-color: black;
  padding-top: 1em;
  padding-bottom: 1em;
  border-top: 2em solid $brandBlue;
}

.grey-container-with-top-border {
  width: 100%;
  background-color: #393939;
  padding-top: 1em;
  padding-bottom: 1em;
  border-top: 2em solid $brandBlue;
}

.brands-black-container {
  width: 100%;
  background-color: black;
  padding-top: 1em;
  margin-bottom: 1em;
}

.center-items {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.larger-box {
  padding-top: 3em;
  padding-bottom: 4em;
}

.centered-half-width {
  width: 50%;
  margin: auto;
}

.icon-select {
  height: 50px;
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-position: center;
}

.react-thumbnail-generator {
  max-height: 100px;
  max-width: 100px;
  overflow: hidden;
}

.button-row {
  width: 35%;
  float: right;
}
