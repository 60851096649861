// Fonts
@import url('https://fonts.googleapis.com/css2?family=Gorditas:wght@700&display=swap');

// Variables
$catsPrimary: #02b9f1;
$catsSecondary: #ebe2ab;
$catsTextLight: #ebe2ab;
$catsTextDark: #000026;
$catsFont: 'Gorditas', cursive;

// Styles
.catsdoingthings {

    // Base
    @include brand (
        $catsPrimary,
        $catsSecondary,
        $catsFont,
        $catsTextLight,
        $catsTextDark
    );

    // Overrides
    .btn.alt,
    .nav-link.active,
    .form-head h4 a,
    .btn.primary:hover {
        color: $catsTextDark !important;
    }

}
