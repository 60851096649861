.submit {

  display: flex;
  background: #ffffff;
  min-height: 100vh;
  z-index: 0;

  @include desktop-down {
    min-height: auto;
  }

  // Overrides
  video { display: none !important }

  // Typography
  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    background: transparent !important;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 0;
    text-align: left;
    line-height: 1;
    color: $dtmBlack;
    font-family: $dtmFont;
    @include mobile-down {
      font-size: 2.5rem;
      padding: 0;
    }
  }

  // Brand Manager
  &.brandManager {
    .banner {
      text-align: center;
      padding: 1rem;
      background-color: #fff9eb;
      color: #333333;
      border-radius: 10px;
      position: sticky;
      top: 0;
      h4 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-size: 1rem;
        font-weight: 400;
        svg {
          margin: 0 1rem 0 0 !important;
          path {
            fill: #ffc02e;
          }
        }
      }
    }
    h2 {
      text-align: center;
    }
    .form-group {
      max-width: 65%;
      margin: 1.5rem auto 0 !important;
    }
    .submit-btn {
      display: block;
      margin: 2rem auto 0;
      width: 200px;
    }
    .categorylist-wrapper {
      &.activeBrand {
        .inactive {
          filter: grayscale(1);
          opacity: 0.5;
          &:hover {
            filter: grayscale(0);
            opacity: 1;
          }
        }
      }
    }
  }

  // User Info
  .userinfo {
    grid-gap: 2rem;
    gap: 2rem;
    max-width: 900px;
    margin-inline: auto;
    padding: 4rem;

    .userinfo-head {
      text-align: center;
      margin: 0 0 4rem;
      line-height: 1.25;
      @include desktop-down {
        margin: 2rem 0;
      }
      h2 {
        font-size: 3rem;
        font-weight: 600;
        margin: 0;
        line-height: 1;
      }
      p {
        font-size: 1.25rem;
        line-height: 1;
      }
    }

    form.login {
      max-width: 400px;
      margin: 2rem auto 0;
    }

    @include desktop-down {
      grid-gap: unset;
      gap: unset;
      padding: 2rem;

      .userinfo-head {
        display: block;
        h2 {
          font-size: 2rem;
        }
        p {
          font-size: 1.05rem
        }
      }
    }

    h1 {
      font-size: 3rem;
      margin-bottom: 3rem;
      background: transparent!important;
      font-weight: 600;
      text-transform: uppercase;
      margin-top: 0;
      text-align: center;
      line-height: 1;
      color: #000;
      font-family: "roc-grotesk",sans-serif;
    }

    .nav-container {
      flex: 0 0 auto;
      background-color: transparent !important;

      @include mobile-down {
        display: flex;
      }

      .sign-in-nav {
        justify-content: space-between;
        align-items: flex-end;
      }
      .nav-item {
        flex: 0 0 33.3333333333%;
      }
      .nav-link {
        font-size: 1.15rem;
        background: transparent;
        padding: 1rem 1.5rem 0.75rem;
        text-align: center;
        font-weight: 500;
        transition: all .2s ease;
        cursor: pointer;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: 4px solid #000;
        color: $dtmBlack;
        line-height: 1;
        font-family: $dtmFont;

        &.active {
          background-color: $dtmBlack;
          color: $dtmYellow;
        }
        @include desktop-down {
          font-size: 1.15rem;
          padding: 10px;
          border-bottom: 2px solid #888;
        }
        @include mobile-down {
          font-size: 1rem;
        }
      }

    }

    // Forgot Password
    .forgot-container {
      text-align: right;
    }
    .forgot {
      width: auto;
      text-decoration: none;
      font-size: 1rem;
      color: #000;
    }

    // Form
    @include desktop-down {
      .form-row {
        grid-gap: 0;
        gap: 0;
      }
    }

  }

  // Disclaimer
  .disclaimer {
    text-align: center;
    padding: 2rem;
    font-size: 1rem;
    line-height: 1.25;
  }
  .disclaimer-container {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    color: #777;
    line-height: 1.35;
    padding: 0 15%;
    margin: 1rem 0;

    @include desktop-down {
      padding: 0
    }

    p {
      margin: 0
    }

  }

  // Errors
  .error-container {
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.35;
    padding: 0 15%;
    margin: 1rem 0;

    p {
      margin: 0
    }

  }

  // Buttons
  .btn-container {
    &.centered {
      text-align: center;
    }
    .btn {
      margin: auto 0 0;
    }
  }

  .form-group {
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin: 1.25rem 0 0 !important;
  }
  label {
    font-family: $bodyFont !important;
    font-size: 1rem;
    margin: 0 0 0.5rem;
    position: relative;
    &.form-check-label {
      margin: 0 0 1rem;
      .checkbox + div {
        line-height: 1.35;
      }
    }
    a {
      color: inherit !important;
      text-decoration: underline !important;
    }
    &.required::after {
      content: "*";
      font-size: 12px;
      position: absolute;
      top: 0;
      right: -0.5rem;
      color: $alertRed;
  }
  }
  textarea,
  input {
    margin: 0;
    background: #f1f1f1;
  }

  // Video Details
  .video-details {
    margin: 0;
    padding: 5rem 0;
    min-height: 100vh;

    @include desktop-down {
      flex-direction: column;
      min-height: unset;
      padding: 0;
      > div {
        width: 100% !important;
      }
    }

    .brand-disclaimer {
      background-color: #f7f7f7;
      padding: 1.5rem;
      border: 2px solid #dddddd;
      border-radius: 5px;

      h4 {
        margin: 0 0 1rem;
      }
      ul {
        margin: 0;
        li {
          list-style: normal;
          line-height: 1.25;
          margin: 0.5rem 0 0;
          padding: 0 0 0 0.5rem;
        }
      }
    }

    .brand-selection {
      h4 {
        font-size: 1rem;
        font-weight: 400;
      }

      .dropdown {
        width: 100%;
        margin-bottom: 2rem;

        &-toggle {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #fcfcfc !important;
          color: $dtmBlack;
          border-radius: 5px;
          text-transform: none;
          font-weight: 400;
          font-size: 1.25rem;
          padding: .75rem !important;
          white-space: normal;

          @include desktop-down {
            font-size: 1.15rem;
          }

          &:hover {
            color: #000000 !important;
            background-color: transparent !important;
          }

          &[aria-expanded="false"] {
            box-shadow: inset 0 0 0 1.5px #ddd !important;
          }

          &[aria-expanded="true"] {
            box-shadow: 0 0 0 0.15rem $dtmBlack !important;
          }

          img {
            width: 50px;
            height: 50px;
            margin-right: 1rem;
            border-radius: 100vw;
            border: 1px solid $dtmBlack;

            @include desktop-down {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }
          }

          .brand-toggle {
            display: flex;
            align-items: center;
          }

          .brand-info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &__name {
              text-align: left;
            }

            &__insta {
              font-size: .7em;
              font-style: italic;
              font-weight: 400;
              margin-top: .2rem;
            }
          }
        }

        &-menu {
          width: 100%;
          overflow-x: auto !important;
          font-size: 1rem;
          top: 10px !important;
          padding-top: 0 !important;
          padding-bottom: 0 !important;

          img {
            width: 50px;
            height: 50px;
            margin-right: 1.25rem;
          }

          .dropdown-item {
            padding: 0.75rem;
            border-bottom: 1px solid #dddddd;
            font-size: 1.15rem;
            font-weight: 500;
            font-family: "roc-grotesk", sans-serif;

            &:hover {
              background-color: #efefef !important;
            }
          }
        }
      }
    }

    .left-col {
      padding: 0 3.5rem;
      width: calc(55% - 2rem);

      @include desktop-down {
        padding: 0;
        margin-bottom: 3rem;
      }
    }
    .right-col {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      padding: 0 3.5rem;
      position: relative;
      width: calc(45% - 2rem);
      &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 2px;
        height: 100%;
        left: 0;
        background: #f1f1f1;

        @include desktop-down {
          display: none;
        }
      }
      h3 {
        margin: 0 0 1rem;
      }

      @include desktop-down {
        padding: 0;
      }
    }

    .consent-section {
      flex: unset;
      margin: 0 0 2rem !important;
    }

    p.center-red-text {
      color: #ff4141;
      margin: 0 !important;
      font-size: .9rem;
      margin: .5rem 0 1rem 0 !important;
    }

    .confirm-section {
      border: 0;
      padding: 0;
      margin: 0;
      position: relative;

      &>label {
        position: absolute;
        text-decoration: none;
        top: 0;
        right: 0;
      }

      .row {
        margin: 0
      }

      &>.row+.row {
        margin-top: 0.5rem;
      }

      h3 {
        font-size: 1.35rem;
        margin-bottom: 1.25rem;

        &:nth-of-type(2) {
          margin-top: 2rem;
        }
      }
    }

    .upload-wrapper {
      background: #f8f8f8;
      border-radius: 14px;
      width: 65%;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ddd;

      & + .upload-wrapper {
        margin-top: 2rem;
      }

      h2 {
        font-size: 1.75rem;
        margin-bottom: 0;
      }

      h5 {
        font-size: 1rem;
        font-weight: normal;
        text-align: center;
        margin-bottom: 1.25rem;
        color: #767676;
        font-weight: 300;
      }

      .file-upload {
        width: 100%;
        margin: 0 auto !important;
      }

      .fileUpload__current {
        flex: 0 0 auto;
      }
    }

    .fileUpload__info {
      display: none;
      &.active {
        display: flex;
      }
    }

    .file-upload {
      background: transparent !important;
      padding: 0;
      border-radius: 0;
      width: 450px;
      margin: 0 auto 5rem !important;
      display: block;

      @include desktop-down {
        width: 100%;
        margin-bottom: 3rem !important;
      }

      &::file-selector-button {
        content: "Upload Media";
        display: inline-block;
        width: auto;
        padding: 0 2rem;
        font-size: 1.25rem;
        line-height: 55px;
        height: 55px;
        text-transform: uppercase;
        border-radius: 10px;
        font-weight: 600;
        cursor: pointer;
        border: none;
        color: $dtmBlack;
        min-width: 300px;
        margin-right: 1.5rem;
        background-color: $dtmYellow;
        font-family: $dtmFont;

        @media only screen and (max-width: 576px) {
          width: 100%;
        }
      }
    }

  }

}

// Thank You Screen
.thank-you {
  text-align: center;
  max-width: 800px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  @include desktop-down {
    height: unset;
    padding: 4rem 2rem 0;
    line-height: 1.25;
  }
  h2 {
    font-size: 5rem;
    line-height: 1;
    margin: 0 0 1.5rem;
    @include mobile-down {
      font-size: 2.5rem;
    }
  }
  p {
    margin-bottom: 2rem;
    font-size: 1.25rem;
    padding: 0 10%;
    @include desktop-down {
      padding: 0
    }
  }
  .btn-row {
    justify-content: center;
    margin: 0;
  }
}

// Confirm Ownership
.confirm-ownership {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 100px 0;

  h1 {
    font-size: 2rem;
    margin: 2rem 0;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .table,
    .media {
      width: 500px;
      max-width: 100%;
    }

    img, video {
      width: 100%;
      margin: 0 0 1rem;
    }

    table {
      width: 100%;
      margin: 0;
      border: 1px solid #000;
      td {
        background-color: #ffffff;
        border: 1px solid #000;
        padding: 8px 16px;
        text-align: left;
        line-height: 1.25;
        &:first-child {
          background-color: #000000;
          color: #ffffff;
        }
        svg {
          width: 25px;
          height: 25px;
        }
      }
      &.borderless {
        border: 0px solid #000;
        tr,
        td {
          background-color: #ffffff;
          border: none;
        }
        td {
          &:first-child {
            padding: 0
          }
          &:last-child {
            padding: 10px 20px;
            white-space: normal;
          }
        }
      }
    }

  }

  .form-check-label {
    max-width: 500px;
  }

  .btn-row {
    justify-content: center;
    margin-top: 2rem;
  }

  .login-error {
    margin: 1rem 0 0;
  }

}
.valign {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  h1 {
    font-size: 2rem;
  }
}