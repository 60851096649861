// Rubik
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap');
$brandFontBlack: 'Rubik' 800;
$brandFontBold: 'Rubik' 700;
$brandFontSemiBold: 'Rubik' 600;
$brandFontMedium: 'Rubik' 500;
$brandFont: 'Rubik' 400;
$brandFontLight: 'Rubik' 300;
$brandFontThin: 'Rubik' 300;

// Roc Grotesk
@import url("https://use.typekit.net/aoi6cdj.css");
