// Fonts
$bodyFont: 'Rubik', sans-serif;
$dtmFont: 'roc-grotesk', sans-serif; // Load via Typekit in index.html

// UI Colors
$error: #e04f5f;
$alertRed: #e04f5f;
$inputBg: #fcfcfc;
$label: #000000;
$successGreen: #32bea6;

// Colors
$dtmYellow: #e1fa00;
$dtmBlack: #000000;

// Legacy Colors
$brandYellow: #FCCC32;
$brandTeal: #30BFB2;
$brandSeaFoam: #8CDBE6;
$brandLightBlue: #2BC6FC;
$brandBlue: #3930EF;
$brandLavender: #D998FB;
$brandPink: #F2308D;
$brandLightGray: #F5F6F8;
$brandMediumGray: #45454F;
$brandTableAltGray: #26242F;
$brandWhite: #FFF;
$brandDark: #171523;
$brandBorder: #707070;

// Font Sizes
$smallFont: 16px;
$smediumFont: 20px;
$mediumFont: 28px;
$largeFont: 48px;
