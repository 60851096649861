.header {
    background-color: #2c2c2c;
    line-height: 1;
    padding: 1.75rem;
    position: -webkit-sticky;
    position: sticky;
    top: 75px;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inline-form {
        display: flex;
        align-items: center;
        gap: 1rem;

        input {
            min-width: 400px;
        }
        button {
            white-space: nowrap;
        }
    }

    h1 {
        font-size: 2rem;
        line-height: 1;
        margin: 0;
        color: #fff;
        text-align: left;
    }
    span {
        color: $dtmYellow
    }
}


.table-wrapper {
    padding-bottom: 2rem;

    table {

        th {
            position: sticky;
            top: 0;
        }

        // Cells
        th,
        td {
            padding: 0.5rem 1rem;
            font-size: 12px;
            line-height: 1;

            /*
            &:first-child {
                padding-left: 1.75rem;
            }
            &:last-child {
                padding-right: 1.75rem;
            }
            */

            &> .flex {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;

                &.left {
                    justify-content: flex-start;
                }
                &.right {
                    justify-content: flex-end;
                }

                &.column {
                    justify-content: center;
                    flex-direction: column;

                    &.left {
                        align-items: flex-start;
                    }
                    &.right {
                        align-items: flex-end;
                    }
                }
            }

        }
        td {
            padding: 0.5rem 1rem;
            font-size: 12px;
        }

        // Cell Classes
        td {
            &.overflow-ellipsis {
                text-overflow: ellipsis;
            }
        }

        // Icons
        td {
            &.icon {
                --iconWidth: 18px;
                --cellWidth: 40px;

                &.small {
                    --iconWidth: 12px;
                }

                padding: 0.5rem calc((var(--cellWidth) - var(--iconWidth)) / 2);

                svg {
                    width: var(--iconWidth);
                }
            }
        }

        // Accents
        td small {
            &.obscure {
                opacity: 0.5;
            }
            &.alert {
                color: $alertRed;
            }
        }

        // Thumbnail
        .thumbnail {
            width: 85px;
            height: 85px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-color: #fff;
            border-radius: 5px;
        }

        // Title
        .title {
            h2 {
                margin: 0;
                font-size: 1.35rem;
                line-height: 1;
            }
            p {
                margin: 0;
                font-size: 0.85rem !important;
            }
        }

        // Tags
        .tag-wrapper {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
        }
        .tag-grid {
            display: flex;
            gap: 0.35rem;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;

            .tag {
                border: 1px solid #ddd;
                padding: 3px 5px;
                border-radius: 3px;
                background: rgba(0, 0, 0, 0.03);
                font-size: 12px;
            }
        }

        // Actions
        .actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            width: 100%;
            gap: 0.75rem;

            .btn {
                padding: 0;
                height: 40px;
                width: 40px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;

                svg {
                    width: 18px;
                    height: 18px;
                }
            }
        }

    }
}