// Fonts
@font-face {
    font-family: 'Londrina Solid';
    src: url(../../assets/fonts/LondrinaSolid/LondrinaSolid-Regular.ttf) format('truetype');
}

// Variables
$animalPrimary: #53b946;
$animalSecondary: #ef823a;
$animalTextLight: #ffffff;
$animalTextDark: #333333;
$animalFont: 'Londrina Solid', sans-serif;

// Styles
.animalsdoingthings {

    // Base
    @include brand (
        $animalPrimary,
        $animalSecondary,
        $animalFont,
        $animalTextLight,
        $animalTextDark
    );

    // Overrides
    .followers :is(h3, p) {
        letter-spacing: 0 !important;
        color: #ffffff !important;
        font-weight: 400 !important;
    }
    .followers h3::before,
    .followers h3::after {
        background-color: #ffffff !important;
    }
    .followers svg path {
        fill: #ffffff !important
    }
    button.btn.primary,
    button.primary.submit-btn {
        background-color: $animalSecondary !important;
        color: #ffffff !important;
    }

}