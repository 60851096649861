/* Category List */

.categorylist {
  margin: 50px auto;
  max-width: 800px;
  h2 {
    font-family: "roc-grotesk", sans-serif;
    text-transform: uppercase;
    font-size: 2.25rem;
    line-height: 1;
    margin: 0 0 2rem;
    text-align: center;
    font-weight: 600;
  }
  .categorylist-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
  }
  .category-container {
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: 250ms all linear;
    &.selected {
      transform: scale(1.15);
    }
    &:hover {
      transform: scale(1.15)
    }
  }
  button.category,
  button.category:active,
  button.category:focus {
    height: 90px;
    width: 90px;
    background-size: cover;
    background-color: transparent !important;
    border: none;
    box-shadow: none !important;
    padding: 0;
    border-radius: 50%;
  }

  /* Mobile */
  @include mobile-down {
    h2 {
      color: $brandWhite;
      font-size: 30px;
      text-align: center;
    }
    .categorylist-wrapper {
      grid: auto/repeat(2, 1fr);
    }
  }

}



/*****************************************
3/22/2022
The code below is NOT confirmed to be used.
*****************************************/

/* ********** HELPERS ********** */

.brand-logo-list {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 40px 30px 0;
  position: initial;
  @include tablet-up {
    padding: 0 15px;
    margin-bottom: 1.75em;
    margin-top: 1.5em;
    justify-content: flex-start;
  }
  .active-blur {
    filter: blur(50px);
    @include tablet-up {
      filter: none;
    }
  }
  button.brand-logo {
    background: none;
    border: none;
    position: relative;
    transition: opacity 1s ease;
    max-width: 50%;
    transition: opacity 0.25s ease;
    opacity: 1;
    padding: 0 10px;
    @include tablet-up {
      margin-bottom: 45px;
      max-width: 19%;
    }
    @include desktop-up {
      width: 13.5%;
    }
    &.inactive, &.active {
      @include tablet-up() {
        display: none;
      }
    }
    img {
      width: 100%;
      transition: opacity 0.5s ease;
    }
    .agg-name {
      display: block;
      color: $brandWhite;
      font-size: 13px;
      line-height: 1;
      @include tablet-up {
        font-size: 16px;
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        margin-top: 6px;
        transition: opacity 0.5s ease;
      }
    }
    &:not(:disabled):hover {
      img {
        opacity: 0.5
      }
      .agg-name {
        display: block;
        opacity: 1;
      }
    }
  }
}

.advertising-aggregators img {
  width: 100%;
  @include tablet-up {
    max-width: 12vh;
    max-height: 12vh;
  }
}

button.aggregatorList.dropdown-item .form-check {
  text-align: left;
}

/* ********** Pagination ********** */

.pagination-bar {
  font-size: 18px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  display: flex;
  margin: 2rem auto;
  justify-content: center;
  gap: 1rem;

  .page-span {
    text-align: right;
  }
  .out-of {
    text-align: left;
  }
  .cyan-button, .white-button {
    height: 40px;
  }
  .form-control {
    text-align: center;

    label {
      font-size: 16px;
      color: $brandMediumGray;
    }
  }

  .count-input {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  input {
    padding: 10px 5px !important;
    max-width: 8ch;
    line-height: 1;
  }
}

.form-check-input {
  display: none !important;
}

// Loader
.loader {
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;

  $w: 48px;
  $h: 56px;
  $xspace: calc($w / 2);
  $yspace: calc(($h / 4) - 1px);
  $speed: 1.5s;

  &.hidden {
    display: none;
  }
  &.contained {
    position: relative;
  }
  .blocks {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-$h, -$w);
  }
  .block {
    width: $w;
    height: $h;
    position: absolute;
    transition: all ease .3s;
    background: url('data:image/svg+xml;utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 684"%3E%3Cpath fill="%23010101" d="M305.7 0L0 170.9v342.3L305.7 684 612 513.2V170.9L305.7 0z"/%3E%3Cpath fill="%23fff" d="M305.7 80.1l-233.6 131 233.6 131 234.2-131-234.2-131"/%3E%3C/svg%3E') no-repeat top center;
  }
  .block1 {
    animation: block1 $speed ease-out infinite;
  }
  .block2 {
    animation: block2 $speed ease-out infinite;
  }
  .block3 {
    animation: block3 $speed ease-out infinite;
    z-index: 2;
  }
  .block4 {
    animation: block4 $speed ease-out infinite;
  }
  @keyframes block1 {
    0%, 40% {
      transform: translate(0,0);
    } 50% {
      transform: translate($xspace,-$yspace);
    } 60%, 100% {
      transform: translate($xspace*2,0);
    }
  }
  @keyframes block2 {
    0%, 20% {
      transform: translate($xspace*2, 0px);
    } 40%, 100% {
      transform: translate($xspace*3, $yspace);
    }
  }
  @keyframes block3 {
    0% {
      transform: translate($xspace*3, $yspace);
    } 20%, 60% {
      transform: translate($xspace*2, $yspace*2);
    } 90%, 100% {
      transform: translate($xspace, $yspace);
    }
  }
  @keyframes block4 {
    0%, 60% {
      transform: translate($xspace, $yspace);
    } 90%, 100% {
      transform: translate(0, 0);
    }
  }
}


// Upload Progress
.upload-progress {
  position: fixed;
  z-index: 2000;
  display: flex;
  overflow: hidden;
  margin: 0;
  top: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(255 255 255 / 95%);
  width: 75vw;
  &.hidden {
    display: none;
  }
  .loaderContents {
    position: relative;
    width: 100%;
    text-align: center;
    & > div{
      margin-top: 5px;
    }
  }
  .progressText {
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    color: $dtmBlack;
    font-family: $dtmFont;
    position: relative;
  }
  .progressText:after {
    display: inline-block;
    animation: dotty steps(1,end) 1s infinite;
    content: '';
    position: absolute;
  }
  @keyframes dotty {
    0%   { content: ''; }
    25%  { content: '.'; }
    50%  { content: '..'; }
    75%  { content: '...'; }
    100% { content: ''; }
  }
  .title {
    font-size: 1.25rem;
    font-weight: 400;
    text-align: center;
    line-height: 1;
    color: $dtmBlack;
  }
  .progressContainer {
    width: 45vw;
    height: 60px;
    display: inline-block;
    background: $dtmBlack;
    margin: 2rem 0 0;
    border-radius: 5px;
    overflow: hidden;
  }
  .progressContainer .progress {
    height: 100%;
    background: $dtmYellow;
    margin: 0;
    border-radius: 4px 0 0 4px;
  }
}

/* ********** Brand Carousel ********** */

.brand-logo {
  max-width: 100%;
}

// Animations

@keyframes delayIn {
   0% {opacity: 0;}
   90% {opacity: 0;}
   100% {opacity: 1;}
}

.delay-desktop {
  @include tablet-up() {
    -webkit-animation-name: delayIn;
    animation-name: delayIn;
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
}

@keyframes slideUp {
   0% {
     padding-top: 1.2em;
     opacity: 0;
    }
   100% {
     padding-top: 0;
     opacity: 1;
    }
}

.slide-up {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: block;
  // height: 50px;
  overflow-y: hidden;
}

.travel {
  position:absolute;
  animation: travel 25s infinite linear;
}


.travel-2 {
  position:absolute;
  animation: travel2 25s infinite linear;
}

@keyframes travel {
  0% { transform: translatex(900%); }
  50% { transform: translatex(800%);}
  100% { transform: translatex(900%); }
}

@keyframes travel2 {
  0% { transform: translatex(100%); }
  50% { transform: translatex(-100%);}
  100% { transform: translatex(100%); }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Toast Messages

.Toastify__toast {
  &-container {
    width: 350px !important;
    z-index: 100000 !important;

    @media only screen and (max-width: 576px) {
      width: 100% !important;
      right: 0 !important;
      top: 0 !important;
      left: unset !important;
    }
  }

  &--error {
    background: $alertRed !important;
    color: #fff !important;
    padding: 1rem 1rem 2rem !important;

    svg {
      fill: #fff;
    }

    .Toastify__progress-bar {
      background: #8a2f39;
    }
  }

  &-body {
    align-items: flex-start;
    font-size: .9rem;
    font-family: 'Rubik', sans-serif;

    &>div:nth-child(2) {
      &>div:first-child {
        font-size: 1rem;
        font-weight: 500;
        color: #fff;
      }

      &>*+* {
        margin-top: .5rem;
      }
    }
  }
}

.Toastify__close-button {
  color: #000;
  margin-top: .25rem;

  &>svg {
    width: 20px;
    height: 20px;
  }
}
