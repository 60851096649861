.loader {
    height: 100vh;
    position: relative;
}

#agreement {

    .container {
        padding: 75px;

        @include mobile-down {
            padding: 75px 25px 50px;
        }
    }

    h1 {
        font-size: 2rem;
        line-height: 1;
        margin: 0 0 2rem;

        @include mobile-down {
            font-size: 1.5rem;
        }
    }

    .proper {
        text-transform: capitalize;
    }

    ul li {
        margin: 0 0 1rem;
        list-style-type: lower-alpha;
        padding: 0 0 0 10px;
    }

    .footer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 50px;
        margin: 100px 0 0 0;
        line-height: 1;

        @include mobile-down {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(6, 1fr);
            grid-column-gap: 0px;
        }

        .item label {
            display: block;
            font-weight: 500;
            border-top: 2px solid #000000;
            margin-top: 7.5px;
            padding: 5px 0 0;
        }

        span.esign-badge {
            padding: 15px 15px 15px 10px;
            background-color: #ffd15d;
            display: flex;
            font-size: 16px;
            border-radius: 3px;
            line-height: 20px;
            align-items: center;
            justify-content: space-between;

            svg {
                width: 95px;
                margin-right: 15px;
            }

            strong {
                font-weight: 500;
            }

            small {
                display: block;
                margin: 5px 0 0;
                font-size: 12px;
            }
        }

    }


}

@media print {

    header {
        display: none;
    }

    #agreement {
        font-size: 12px;

        .container {
            padding: 25px 0;
        }

        h1 {
            font-size: 24px;
        }

        p {
            font-size: 12px;
        }

        .footer {
            margin: 50px 0;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(6, 1fr);
            grid-column-gap: 0px;

            .item label {
                font-size: 12px;
            }

            span.esign-badge {
                justify-content: center;
                text-align: center;

                svg {
                    display: none
                }
            }
        }

    }
}