// Fonts
// Typekit (https://use.typekit.net/bdz8pcq.css)
@import url("https://use.typekit.net/bdz8pcq.css");

// Variables
$dyelPrimary: #f5d722;
$dyelSecondary: #2f2f2f;
$dyelTextLight: #ffffff;
$dyelTextDark: #2f2f2f;
$dyelFont: 'tablet-gothic-compressed', sans-serif;

// Styles
.doyouevenlift {

    // Base
    @include brand (
        $dyelPrimary,
        $dyelSecondary,
        $dyelFont,
        $dyelTextLight,
        $dyelTextDark
    );

    // Overrides
    nav h2 {
        font-size: 2rem !important;
    }

}
