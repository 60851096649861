// Fonts
// Typekit (https://use.typekit.net/cgo5fal.css)
@import url("https://p.typekit.net/p.css?s=1&k=cgo5fal&ht=tk&f=15488.15493&a=9071714&app=typekit&e=css");
@font-face {
    font-family: "cubano";
    src: url("https://use.typekit.net/af/a171c7/00000000000000007735a084/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"),
         url("https://use.typekit.net/af/a171c7/00000000000000007735a084/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"),
         url("https://use.typekit.net/af/a171c7/00000000000000007735a084/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

// Variables
$ftPrimary: #98d798;
$ftSecondary: #9b86be;
$ftTextLight: #ffffff;
$ftTextDark: #333333;
$ftFont: 'cubano', sans-serif;

// Styles
.fourtwenty {

    // Base
    @include brand (
        $ftPrimary,
        $ftSecondary,
        $ftFont,
        $ftTextLight,
        $ftTextDark
    );

    // Overrides

}