// Fonts
@import url('https://fonts.googleapis.com/css2?family=Turret+Road:wght@800&display=swap');

// Variables
$gamingPrimary: #000000;
$gamingSecondary: #36e5f3;
$gamingTextLight: #36e5f3;
$gamingTextDark: #000000;
$gamingFont: 'Turret Road', cursive;

// Styles
.houseofgaming {

    // Base
    @include brand (
        $gamingPrimary,
        $gamingSecondary,
        $gamingFont,
        $gamingTextLight,
        $gamingTextDark
    );

    // Overrides
    .btn.alt,
    .nav-link.active,
    .form-head h4 a,
    .btn.primary:hover {
        color: $gamingTextDark !important;
    }

    .btn.primary,
    .logout,
    nav,
    .mobile-header,
    .mobile-dropdown,
    .checkbox.checked::after,
    .mobile-dropdown .admin-navigation a,
    .file-upload::file-selector-button,
    .branding-container h2,
    .followers :is(h3, p),
    .admin-navigation a {
        color: $gamingTextLight !important;
    }

    .followers svg path {
        fill: $gamingTextLight !important;
    }

    .followers h3::before,
    .followers h3::after {
        background: $gamingTextLight !important;
    }
}
