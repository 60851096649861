// Fonts
@import url('https://fonts.googleapis.com/css2?family=Timmana&display=swap');

// Variables
$brunchPrimary: #2dbdac;
$brunchSecondary: #ffffff;
$brunchTextLight: #ffffff;
$brunchTextDark: #2dbdac;
$brunchFont: 'Timmana', sans-serif;

// Styles
.tuesdaybrunch {

    // Base
    @include brand (
        $brunchPrimary,
        $brunchSecondary,
        $brunchFont,
        $brunchTextLight,
        $brunchTextDark
    );

    // Overrides
    nav h2 {
        font-size: 2rem !important;
    }

    .btn {
        padding: 1.15rem 2rem 0.8rem !important;
    }

    .btn.alt,
    .form-head h4 a,
    .btn.primary:hover {
        color: $brunchTextDark !important;
    }

    .nav-link.active {
        background-color: $brunchPrimary !important;
    }

    .nav-link {
        border-color: rgba($brunchPrimary, 0.2) !important;
    }

    .btn.primary,
    .logout,
    nav,
    .mobile-header,
    .mobile-dropdown,
    .checkbox.checked::after,
    .mobile-dropdown .admin-navigation a,
    .file-upload::file-selector-button,
    .branding-container h2,
    .followers :is(h3, p),
    .admin-navigation a {
        color: $brunchTextLight !important;
    }

    .followers svg path {
        fill: $brunchTextLight !important;
    }

    .followers h3::before,
    .followers h3::after {
        background: $brunchTextLight !important;
    }
}
