.slide.full {
    width: 85vw;

    .slide-close {
      left: calc(15vw) !important;
    }
    .right-col {
      padding: 2.5rem !important;
      gap: 1rem;
    }
  }

  .slide.half {
    width: 50vw !important;

    .slide-close {
      left: calc(50vw) !important;
    }

    .slide-wrapper {
      display: flex !important;
      flex-direction: column !important;
      gap: 0 !important;
    }

    .left-col {
      display: none;
    }
    .right-col {
      padding: 2.5rem !important;
      gap: 2rem;
    }
  }

  .slide {
    background-color: white;
    box-shadow: 0 0 80px #888;
    height: 100vh;
    overflow-x: visible;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000000;

    h2 {
      margin-bottom: 1rem !important;
    }

    .slide-container {
      height: 100%;
      min-height: 100%;
    }

    .slide-wrapper {
      position: relative;
      display: grid;
      grid: auto/repeat(2, 1fr);
      align-items: stretch;
      justify-content: space-between;
      height: 100%;

      &> div {
        overflow: scroll;
        height: 100%;
      }

      .left-col {
        padding: 2.5rem 1.25rem 2.5rem 2.5rem;
      }

      .right-col {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 2.5rem 2.5rem 2.5rem 1.25rem;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    table td svg {
      margin: 0
    }
    table td:nth-child(2) {
      white-space: normal;
    }

    // Close
    .slide-close {
      z-index: 45002 !important;
      position: fixed !important;
      top: 50% !important;
      width: 50px !important;
      height: 50px !important;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      border-radius: 50% !important;
      padding: 0 !important;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzY1LjY5NiAzNjUuNjk2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0yNDMuMTg4IDE4Mi44NiAzNTYuMzIgNjkuNzI2YzEyLjUtMTIuNSAxMi41LTMyLjc2NiAwLTQ1LjI0N0wzNDEuMjM4IDkuMzk4Yy0xMi41MDQtMTIuNTAzLTMyLjc3LTEyLjUwMy00NS4yNSAwTDE4Mi44NiAxMjIuNTI4IDY5LjcyNyA5LjM3NGMtMTIuNS0xMi41LTMyLjc2Ni0xMi41LTQ1LjI0NyAwTDkuMzc1IDI0LjQ1N2MtMTIuNSAxMi41MDQtMTIuNSAzMi43NyAwIDQ1LjI1bDExMy4xNTIgMTEzLjE1Mkw5LjM5OCAyOTUuOTljLTEyLjUwMyAxMi41MDMtMTIuNTAzIDMyLjc2OSAwIDQ1LjI1TDI0LjQ4IDM1Ni4zMmMxMi41IDEyLjUgMzIuNzY2IDEyLjUgNDUuMjQ3IDBsMTEzLjEzMi0xMTMuMTMyTDI5NS45OSAzNTYuMzJjMTIuNTAzIDEyLjUgMzIuNzY5IDEyLjUgNDUuMjUgMGwxNS4wODEtMTUuMDgyYzEyLjUtMTIuNTA0IDEyLjUtMzIuNzcgMC00NS4yNXptMCAwIi8+PC9zdmc+) !important;
      background-size: 20px !important;
      background-repeat: no-repeat !important;
      background-position: 50% 50% !important;
      background-color: $alertRed !important;
      cursor: pointer !important;
      transition: 500ms all cubic-bezier(0.075, 0.82, 0.165, 1) !important;

      &:hover {
        background-color: $dtmBlack !important;
      }
    }

    // Video Player
    .player-wrapper {
      position: relative;
      padding-top: 56.25%;
      width: 100%;
      background-color: #f1f3f4;
      border-radius: 10px;
      overflow: hidden;

      > div {
        position: absolute;
        top: 0;
        left: 0;
      }

    }

    // Image
    img {
      margin: 0 auto 1rem;
      max-width: 100%;
      max-height: 400px;
    }

}
