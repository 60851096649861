// Fonts
@import url('https://fonts.googleapis.com/css2?family=Sedgwick+Ave&display=swap');

// Variables
$skatersPrimary: #f1f7d3;
$skatersSecondary: #ee242d;
$skatersTextLight: #f1f7d3;
$skatersTextDark: #333333;
$skatersFont: 'Sedgwick Ave', cursive;

// Styles
.skatersdoingthings {

    $skatersTertiary: #92d832;

    // Base
    @include brand (
        $skatersPrimary,
        $skatersSecondary,
        $skatersFont,
        $skatersTextLight,
        $skatersTextDark
    );

    // Overrides
    .btn.primary,
    .file-upload::file-selector-button,
    .checkbox.checked::after {
        background-color: $skatersTertiary !important;
    }

}
