// Fonts
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@700&display=swap');

// Variables
$momPrimary: #f79161;
$momSecondary: #da3e4d;
$momTextLight: #fff;
$momTextDark: #da3e4d;
$momFont: 'Josefin Slab', serif;

// Styles
.neatmom {

    // Base
    @include brand (
        $momPrimary,
        $momSecondary,
        $momFont,
        $momTextLight,
        $momTextDark
    );

    // Overrides
    nav h2 {
        font-size: 2rem !important;
    }

    nav img {
        border: 2px solid $momTextLight !important;
        border-radius: 100vw !important;
    }

    .btn.primary,
    .logout,
    .file-upload::file-selector-button,
    .admin-navigation a {
        color: #fff !important;
    }
}
