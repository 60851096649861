// Fonts
@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&display=swap');

// Variables
$kidsPrimary: #24aae2;
$kidsSecondary: #e995bf;
$kidsTextLight: #eff3fd;
$kidsTextDark: #660c3c;
$kidsFont: 'Balsamiq Sans', cursive;

// Styles
.kidsdoingthings {

    // Base
    @include brand (
        $kidsPrimary,
        $kidsSecondary,
        $kidsFont,
        $kidsTextLight,
        $kidsTextDark
    );

    // Overrides
    .btn.alt,
    .nav-link.active,
    .form-head h4 a,
    .btn.primary:hover {
        color: $kidsTextDark !important;
    }

    .btn.primary,
    .logout,
    nav,
    .mobile-header,
    .mobile-dropdown,
    .checkbox.checked::after,
    .mobile-dropdown .admin-navigation a,
    .file-upload::file-selector-button,
    .branding-container h2,
    .followers :is(h3, p),
    .admin-navigation a {
        color: $kidsTextLight !important;
    }

    .followers svg path {
        fill: $kidsTextLight !important;
    }

    .followers h3::before,
    .followers h3::after {
        background: $kidsTextLight !important;
    }
}
