/********** CARD Styles **********/

.card-header, .card-footer {
  background-color: black;
  color: $brandBlue;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card-header img {
  margin-top: .5em;
  margin-bottom: .5em;
}

.auth-card-body {
  align-items: center;
  justify-content: center;
}

.line-break {
  border: 1px solid $brandLightBlue;
  margin-bottom: 1em;
}

.editable-input {
  border: 1px solid #d3d3d3;
  border-radius: .25em;
  padding: .25em;
}

.centered-row {
 justify-content: center;
}

.recaptcha-container {
  display: flex;
  max-width: 90%;
  justify-content: center;
  margin: auto;
  margin-top: 1.5em;
}

.last-question {
  padding-top: 1em;
}

.mobile-banner {
  background-color: black;
  padding: 1em;
}

 .side-by-side {
   justify-content: space-between;
   padding-top: 1em;
 }

.padding-bottom {
  padding-bottom: .5em;
}

.padding-top {
  padding-top: 1em;
}

.full-height {
  height: 100%;
}

.custom-dropdown-item {
  display: flex;
}

.error-box {
  color: #d3d3d3;
  background-color: black;
  border-radius: .5em;
  padding: 1em;
}

.page-select {
  max-width: 20%;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
}

.chSsAf, .sc-bZQynM, .bpzdHV {
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
}