// Button Containers
.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  gap: 1rem;
  align-items: baseline;

  @include mobile-down {
    align-items: center;
  }
}

/*
.go-back {
  font-family: $bodyFont !important;
  text-transform: none !important;
  color: #333333 !important;
  display: inline-block;
  width: auto;
  height: unset;

  @include mobile-down {
    margin: 0 auto;
  }
}
*/

.btn {
  display: inline-block;
  width: auto;
  padding: 1rem 2rem;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  font-weight: 600;
  margin: 0;
  background: $dtmYellow;
  color: $dtmBlack;
  font-family: $dtmFont;

  &:hover {
    background: $dtmBlack !important;
    color: $dtmYellow !important;
  }

  &:active {
    background: $dtmBlack;
    color: $dtmYellow;
  }

  &:focus {
    background: $dtmBlack;
    color: $dtmYellow;
  }

  @include mobile-down {
    width: 100%;
  }

  &.outline {
    background: transparent !important;
  }

  &:focus { box-shadow: none !important; }

  &.dtm-alt {
    background: $dtmYellow;
    color: #000 !important;
    border: 0;
    font-size: 1.15rem;
    font-weight: 400;
    height: 48px;
    padding: 0 30px;
    text-transform: none;
    border-radius: 10px;
  }

  &.simple {
    font-size: 1rem;
    font-family: $dtmFont;
    text-transform: none;
    background: $dtmBlack;
    color: #ffffff;
    font-weight: 500;
  }

  &.danger {
    background-color: $alertRed;
    color: #fff;
    &:hover {
      background-color: #a13441;
    }
  }

  &.page-button {
    background: #000 !important;
    color: #fff !important;
    border: 0;
    position: relative;
    display: flex;
    padding: 15px 20px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 1rem;
    gap: 15px;

    &.disabled {
      background: #dddddd !important;
      opacity: 0.5;
      filter: grayscale(1);
    }

    svg {
      width: 12px;
      height: 12px;
      margin: 1px 0 0 0;
      path {
        fill: $dtmYellow
      }
    }

  }
}