#dashboard {

  @include desktop-down {
    padding: 3rem 1rem;
  }

  // Options
  #options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    background-color: #fff;
    border-bottom: 1px solid rgba(0,0,0,.1);
    position: sticky;
    top: 75px;
    z-index: 10000;

    @include desktop-down {
      flex-direction: column;
    }

    button.btn {
      padding: 12px 30px 10px 12px;
      border-radius: 6px;
      font-size: 14px !important;
    }

    .dropdown-toggle::after {
      vertical-align: unset;
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
    }
  }

  // Search
  #search {
    align-items: center;
    width: unset;
    flex-wrap: nowrap;

    .dropdown-toggle {
      color: #ffffff;
    }
    .dropdown-toggle::after {
      color: $dtmYellow
    }
    input#searchInput {
      padding: 12px !important;
      font-size: 14px !important;
      margin: 0 2rem 0 0.5rem !important;
      width: 230px;
      height: 36px;
    }
  }

  // Filter
  #filter {
    justify-content: flex-end;
    grid-gap: 10px;

    a.dropdown-toggle.nav-link {
      position: relative;
      padding: 12px 30px 10px 12px;
      border-radius: 6px;
      font-size: 14px;
      line-height: 1;
      background-color: #000000;
      color: #ffffff;
    }
    .dropdown-toggle::after {
      color: $dtmYellow
    }
    .dropdown-item {
      .form-check-label {
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
      }
      .checkbox {
        margin-top: 0;
        margin-right: 10px;
      }
      svg[data-icon="thumbs-up"] path { fill: #212529 }
      svg[data-icon="thumbs-down"] path { fill: #212529 }
      svg[data-icon="bookmark"] path { fill: #30bea6 }
      svg[data-icon="minus-circle"] path { fill: #333 }
      svg[data-icon="fire"] path {
        transition: fill 0.35s ease;
        -webkit-animation: viral 2s linear infinite;
        animation: viral 2s linear infinite;
        -webkit-animation-direction: alternate;
        animation-direction: alternate;
      }
      svg[data-icon="exclamation-triangle"] path { fill: #df4f5f }
    }

    label.form-check-label svg {
      margin-left: 0;
      margin-right: 10px;
    }
    label.form-check-label svg path {
      fill: #eee;
    }
  }

  // Export
  #toggle-export {
    position: relative;
    padding: 12px 12px 10px 12px !important;
    border-radius: 6px !important;
    line-height: 1;
    background-color: #000000;
    color: #ffffff;
    font-family: "roc-grotesk", sans-serif !important;
    font-weight: 400;
    text-transform: none;
  }

  #export {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 2rem 0;
    grid-gap: 2rem;

    button {
      margin: 0;
      border-radius: 6px !important;
    }
  }

}


/*************************************
Tagging
*************************************/

.admin-tags {
  width: 100%;
  margin-top: auto;

  form {
    display: none
  }

  .dropdown-toggle {
    padding-left: 0;
    color: $dtmYellow;
    display: block;
    text-align: left;
    margin-bottom: 1rem;
  }

  .apply-tag-label {
    display: block;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1;
    margin: 50px 0 15px;
  }

  .tags {
    font-weight: normal !important;
    text-transform: none !important;
    padding: 10px !important;
    background: transparent !important;
    border: 2px solid $dtmYellow !important;
    color: $dtmYellow !important;
    margin-bottom: 10px;
  }
}
.admin-tags button {
  width: 100%
}
.pendingTag {
  background: $brandMediumGray;
}
.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .tag-wrapper {
    position: relative;
    border-radius: 4px;
    font-size: 14px;
    padding: 5px 28px 5px 10px;
    background-color: rgb(50 190 166 / 16%);
    border: 2px solid #32bea6;

    .ReactTags__remove {
      position: absolute;
      background: transparent;
      font-size: 1.25rem;
      border: none;
      width: unset;
      padding: 0;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .ReactTags__tagInput {
    width: 100%
  }
}
.ReactTags__suggestions {
  font-size: 1rem;
  padding: 0 !important;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  display: flex;
  padding: 0 !important;
  margin: 0;
  text-align: left;
  position: relative;
  flex-wrap: wrap;
  gap: 5px;

  li {
    padding: 7px 15px;
    cursor: pointer;
    color: #fff;
    transition: all 0.2s ease;
    border-radius: 10px;
    background-color: #000000;
    line-height: 1;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 400;

    &:hover {
      background: $dtmYellow;
      color: #000 !important;

      mark {
        color: #000 !important;
      }
    }
  }
}
div.ReactTags__suggestions li mark {
  color: $dtmYellow !important;
  padding: 0 !important;
  font-weight: 500;
  background: transparent !important;
  transition: all .2s ease;
}


/*************************************
Tables
*************************************/

table {
  width: 100%;

  .align-left {
    text-align: left
  }

  .align-center {
    text-align: center
  }

  .align-right {
    text-align: right
  }

  thead {
    background-color: #fff;
    box-shadow: 0 2px 15px 0 rgb(0 0 0 / 15%);
    position: sticky;
    z-index: 10;
    th {
      background-color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      padding: 10px;
      white-space: nowrap;
    }
  }

  tr {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);

    &.active {
      .details {
        transform: rotate(-90deg);
      }
      td {
        background-color: #e0e0e0;
        border-color: #e0e0e0;
      }
    }

    &:nth-child(odd),
    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }
  }

  /* Cells */

  td {

    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    white-space: nowrap;
    padding: 7px 10px;
    overflow: hidden;
    transition: .3s ease;
    transition-property: width, min-width, padding, opacity;
    border-right: 1px solid rgba(0, 0, 0, 0.02);

    &:last-child {
      border-right: 0;
    }

    &.editable {
      outline: none !important;
      box-shadow: none !important;
      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    input,
    input:hover,
    input:focus,
    input:active {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
      background-color: transparent;
    }

    // Logo
    img {
      width: 30px;
      height: 30px;
    }

    // Icons
    svg {
      box-shadow: none !important;

      &.inactive {
        opacity: 0.15;

        &:hover {
          opacity: 1;

          &.green {
            path {
              fill: #32bea6
            }
          }

          &.red {
            path {
              fill: #e04f5f
            }
          }

        }
      }

      &.details {
        transition: transform .35s ease;
      }

      &.viral {
        &.active {
          path {
            transition: fill .35s ease;
            animation: viral 2s linear infinite;
            animation-direction: alternate;
          }
        }

        &:hover {
          path {
            transition: fill .35s ease;
            animation: viral 2s linear infinite;
            animation-direction: alternate;
          }
        }

      }

    }

    svg.video-icon path,
    svg.image-icon path {
      fill: #0096ff
    }

  }

}


/*************************************
Animations
*************************************/

@keyframes viral {
  from {
    fill: #ffc100
  }
  to {
    fill: #ff7400
  }
}

@keyframes viralBtn {
  from {
    background-color: #ffc100
  }

  to {
    background-color: #ff7400
  }
}

/*************************************
Submission Viewer
*************************************/

.slide {

  .action-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span.icon-btn {
      width: 25px;
      height: 25px;
      background-color: #000;
      color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    svg {
      cursor: pointer;
    }
    button {
      background: unset !important;
      padding: 0;
      color: #202428 !important;
      border: none;
    }
  }

  // Actions
  .actions {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
    width: 100%;
    margin: 1rem 0 auto;

    .btn {
      background-color: #000;
      color: #fff;
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
      height: 50px;
      padding: 0;
      text-transform: unset;
      position: relative;
      opacity: 0.25;

      &.active,
      &:hover {
        opacity: 1
      }
      &.viral {
        transition: fill .35s ease;
        animation: viralBtn 2s linear infinite;
        animation-direction: alternate;
      }
      &.approve {
        background-color: $successGreen;
      }
      &.disapprove {
        background-color: $alertRed;
      }
      &.nsfw {
        color: $dtmYellow;
        background-color: $dtmBlack
      }
      &.additional {
        grid-column: 1/5;
      }

      span,
      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
      svg {
        font-size: 1.5rem;
        opacity: 1;
      }
      span {
        opacity: 0;
        transition: 250ms all cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      &:hover {
        span {
          opacity: 1
        }
        svg {
          opacity: 0
        }
      }

    }

    .additional-container {
      grid-column: 1/5;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      border: 2px solid $dtmBlack;
      border-radius: 10px;
      margin: 1rem 0 0;

      .btn {
        width: 100%;
        opacity: 1;

        &.additional {
          background-color: transparent;
          color: $dtmBlack;

          span {
            opacity: 1 !important;
            position: relative;
            left: unset;
            top: unset;
            transform: none;
            margin-left: 10px;
          }

          svg {
            opacity: 1 !important;
            position: relative;
            display: inline-block;
            left: unset;
            top: unset;
            transform: none;
            font-size: 1rem;
          }
        }
      }

      .actions-wrapper {
        display: grid;
        grid: auto/repeat(3, 1fr);
        grid-gap: 12px;
        padding: 0 12px 12px 12px;
      }
    }
  }

  // Tags
  input.ReactTags__tagInputField,
  input:not([type=file]):focus {
    border: none !important;
    box-shadow: none !important;
    padding: 10px 0 !important;
    border-bottom: 2px solid #ccc !important;
    border-radius: 0 !important;
    margin: 1rem 0 0;
    outline: none !important;
    width: 100% !important;
  }

  h2 {
    font-weight: 600;
    line-height: 1;
    margin: 0 0 1.5rem;
    font-size: 1.35rem;
    &:first-child {
      margin-bottom: 0.25rem;
    }
  }

  table {
    margin-bottom: 1.5rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    &:last-child {
      margin-bottom: 0 !important;
    }
    tr {
      border: 1px solid rgba(0, 0, 0, 0.1);
      td {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 14px;
        &:first-child {
          font-weight: 600;
          width: 0.1%;
          white-space: nowrap;
        }
        &:last-child {
          border-right: 0;
        }
        &.copyable {
          position: relative;
          line-height: 1;
          cursor: pointer;
          &:active,
          &.active {
            transition: 0ms ease all;
            svg path {
              fill: $successGreen;
            }
          }
          svg {
            font-size: 18px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            path {
              fill: $dtmBlack;
            }
          }
        }
        > span.alert {
          padding: 0;
          color: $alertRed;
          font-weight: 600;
        }
      }
    }
  }
  .actions-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row dense;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    button {
      font-family: unset;
      text-transform: capitalize;
      font-size: 1rem;
    }
  }

}


/*************************************
Modal
*************************************/

.dashboard-modal {
  width: 75%;
  font-size: 16px;

  .modal-body {
    padding: 0;
  }

  .modal-footer {
    gap: 1rem;
    margin-top: 2rem;

    button {
      width: calc(50% - .5rem);
    }
  }

  .form-group {
    gap: 0;
  }
}

.dashboard-modal,
.modal-header,
.mobile-dashboard {
  font-size: 30px;
  justify-content: center;

  .modal-title {
    color: $dtmYellow;
  }

  .form-control {
    background-color: #f1f1f1 !important;
    border: none;
    padding: .75rem 1rem;
    color: #000 !important;
    border-radius: 10px;
  }

  .essay-input input {
    height: 240px;
    border-radius: 17px;
    width: 100%;
  }

  .brand-options {
    padding-top: 1em;
    margin-bottom: 1em;

    &>div {
      display: grid;
      grid: auto / repeat(5, 1fr);
      gap: 10px;
      justify-items: center;
    }

    h1 {
      font-size: 20px;
      margin-bottom: 1rem;
      color: $dtmYellow;
      font-weight: normal;
    }

    .btn {
      padding: 0;
      height: 75px;
      width: 75px;
      background-size: contain;
      border-radius: 50%;
      border: 3px solid $dtmYellow;
      opacity: .5;
      transition: all .3s ease;

      &.sel {
        opacity: 1;
      }
    }
  }

  .copy {
    margin-top: 1rem;
    margin-bottom: 1rem;

    button {
      width: 100%;
      margin: 0;
    }
  }
}
.dahboard-modal-body,
.modal-body {
  h1 {
    font-size: 18px;
    padding: .25em;
    color: $dtmYellow;
  }

  p {
    color: $dtmYellow;
    text-align: left;
    margin: 0 !important;
    width: 100% !important;
  }

  background-color: $brandDark;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: flex-start;

  .thumbnail-edit-video {
    max-width: 100%;
  }

  .selected-time {
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: $dtmYellow;
  }

  video {
    padding: 1em;
  }

  input,
  input:focus,
  textarea,
  textarea:focus,
  select,
  select:focus {
    color: #000;
    background: #f1f1f1 !important;
    margin-top: .5rem;
  }

  .form-group label {
    width: 100%;
    color: $dtmYellow;
    margin-top: 1rem;
  }

  .form-check {
    padding: 0;

    .form-check-label {
      display: flex;
      align-items: center;

      .checkbox {
        margin-top: 0;
        margin-right: 10px;
      }
    }
  }

  .row.checkbox-input {
    margin-top: 1rem !important;

    .form-check-label>div {
      display: flex;
      color: $dtmYellow;
    }
  }
}

// Export Loader
.export-loader {
  width: 120px;
  height: 20px;
  transform: skewX(-45deg);
  background:
    linear-gradient($dtmYellow 0 0) left -30px top 0/30px 20px no-repeat
    #000;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  100% {background-position: right -30px top 0}
}