@mixin mobile-down {
    @media (max-width: 576px) {
        @content;
    }
}
@mixin mobile-up {
    @media (min-width: 576px) {
        @content;
    }
}
@mixin tablet-down {
    @media (max-width: 768px) {
        @content;
    }
}
@mixin tablet-up {
    @media (min-width: 768px) {
        @content;
    }
}
@mixin desktop-up {
    @media (min-width: 992px) {
        @content;
    }
}
@mixin desktop-down {
    @media (max-width: 992px) {
        @content;
    }
}
@mixin desktop-lg-up {
    @media (min-width: 1200px) {
        @content;
    }
}
@mixin desktop-xl-up {
    @media (min-width: 1450px) {
        @content;
    }
}

/* Brand Themes */

@mixin brand (
    $primaryColor,
    $secondaryColor,
    $font,
    $primaryText,
    $secondaryText
) {

    // Primary Color Elements
    nav,
    .btn.primary,
    .mobile-header,
    .mobile-dropdown,
    .file-upload::file-selector-button,
    .checkbox.checked::after {
        background-color: $primaryColor !important;
    }
    .btn.previous {
        color: $primaryColor !important;
    }
    .btn.outline {
        box-shadow: inset 0 0 0 0.15rem $primaryColor !important;
    }
    input:not([type="file"]):focus,
    .fileUpload:hover,
    textarea:focus,
    select:focus {
        box-shadow: 0 0 0 0.15rem $primaryColor;
    }

    // Secondary Color Elements
    .btn.alt,
    .nav-link.active,
    .form-head h4 a,
    .btn.primary:hover {
        background: $secondaryColor !important;
    }
    .nav-link {
        border-color: rgb($secondaryColor, 0.2) !important;
    }

    // Font Elements
    h1,
    h2,
    h3,
    .btn.primary,
    .btn.alt,
    .nav-link,
    .file-upload::file-selector-button {
        font-family: $font !important;
    }

    // Primary Text Elements
    .btn.alt,
    .nav-link.active,
    .form-head h4 a,
    .btn.primary:hover {
        color: $primaryText !important;
    }

    .menu-button svg :is(g, path) {
        stroke: $primaryText !important;
    }

    // Secondary Text Elements
    .btn.primary,
    .logout,
    nav,
    .mobile-header,
    .mobile-dropdown,
    .checkbox.checked::after,
    .mobile-dropdown .admin-navigation a,
    .file-upload::file-selector-button,
    .branding-container h2,
    .followers :is(h3, p),
    .admin-navigation a {
        color: $secondaryText !important;
    }
    .followers svg path {
        fill: $secondaryText !important;
    }
    .followers h3::before,
    .followers h3::after {
        background: $secondaryText !important;
    }

}