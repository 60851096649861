.mrss {

    // Slide
    .slide {

        h4.banner {
            margin-bottom: 2rem;
            padding: 1.15rem 1.5rem;
            font-size: 1.15rem;
            font-weight: 400;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            background-color: $dtmBlack;
            border-radius: 10px;

            span {
                margin-right: 1.5rem;
                transform: scale(1.75);

                path {
                    fill: $dtmYellow
                }
            }

            strong {
                margin: 0 8px;
                color: $dtmYellow;
            }
        }

        .mrss-actions {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dropdown {
                display: flex;
                align-items: flex-end;
            }

            span.dropdown-text>button {
                background-color: #f6f6f6 !important;
                color: #000 !important;
                padding: 0 !important;
                height: 40px !important;
                min-width: 150px !important;
                border-radius: 0 !important;
                margin: 0 1rem;
                border-bottom: 2px solid #000;
            }
        }
    }

    // Grid
    .grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 20px;
        padding: 20px;

        .grid-item {
            background-color: $dtmBlack;
            position: relative;

            .toggle-details {
                position: absolute;
                bottom: 1rem;
                right: 1rem;
                padding: 0;
                height: 40px;
                border-radius: 50%;
                width: 40px;
            }

            &.media-image {
                height: 400px;
                width: 100%;
                text-align: center;
                background-color: #000000 !important;

                img {
                    height: 400px;
                    width: auto;
                }
            }
        }
    }

}