// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

// Variables
$opPrimary: #000000;
$opSecondary: #ffffff;
$opTextLight: #000000;
$opTextDark: #ffffff;
$opFont: 'Roboto', sans-serif;

// Styles
.ordinarypeople {

    // Base
    @include brand (
        $opPrimary,
        $opSecondary,
        $opFont,
        $opTextLight,
        $opTextDark
    );

    // Overrides
    nav h2 {
        font-size: 1.2rem !important;
    }

    .nav-link,
    .btn {
        font-style: italic;
    }

}
