// Modal Effects

body.modal-open {
	overflow: hidden;
  }
  
.modal + .modal-backdrop {
backdrop-filter: blur(0);
transition: backdrop-filter .5s ease;
background: transparent !important;
}

.fade {
backdrop-filter: blur(0);
transition: backdrop-filter .5s ease;
}

.modal + .modal-backdrop.show {
backdrop-filter: blur(5px);
opacity: 1;
}

// Modal

.modal {

	// Container

	.modal-dialog {
		margin: auto;
		height: 100%;
		display: grid;
		align-items: center;

		@include mobile-down {
			margin: auto 5%;
		}
	}

	// Content Box

	.modal-content {
		border: 0;
		font-family: $bodyFont;
		background: $dtmBlack;
		border-radius: 16px;
		padding: 3rem;

		@include mobile-down {
			padding: 2rem;
		}
	}

	// Headers

	.modal-header {
		font-family: $dtmFont;
		font-weight: 600;
		background: transparent !important;
		border: 0 !important;
		padding: 0;
		margin-bottom: 1rem;

		.modal-title {
			font-size: 2rem;
			text-align: center;
			line-height: 1;
		}
	}

	// Body

	.modal-body {
		font-family: $bodyFont !important;
		text-align: center;
		border: 0 !important;
		background: transparent !important;
		padding: 0;

		p {
			text-align: center;

			@include mobile-down {
				width: 100%;
			}
		}
	}

	// Buttons

	button.close {
		position: absolute;
		top: 1rem;
		right: 1rem;
		background: transparent;
		border: 0;
	}

	.modal-footer {
		border: 0 !important;
		background: transparent !important;
		padding: 0;
		margin-top: 1rem;
	}

	input::placeholder {
		color: #d1d1d1;
	}

	.login-error {
		font-family: $bodyFont;
		margin-top: 1rem;
	}

	input[type="submit"],
	.btn {
		margin: 0;
		width: 100%;
		font-family: $dtmFont;
		background: $dtmYellow;
		font-weight: 500;
		border: 0;
		text-transform: none;
		color: $dtmBlack;
	}
}
