/**
 * Basic typography style for copy text
 */

body {
  font-family: $bodyFont;
}

h1 {
  font-size: 4rem;
  margin-bottom: 2rem;
  background: transparent !important;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 0;
  text-align: center;
  line-height: 1;
  color: $dtmBlack;

  @include mobile-down {
    font-size: 2.5rem;
    padding: 0;
  }
}
